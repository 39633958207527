import React from "react";
import Layout from "@/components/layout";
import Footer from "@/components/footer";
import HeaderInner from "@/components/header-one";
import SearchContextProvider from "@/context/search-context";
import MenuContextProvider from "@/context/menu-context";

const Success = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="Obrigado pelo seu interesse">
          <HeaderInner />
            <div style={{ marginTop: 60, textAlign: 'center', background: "#5a48f6" }}>
              <iframe src="https://forms.monday.com/forms/embed/4a67836ad5b3234fd4661e59b13ad9aa?r=use1" width="750" height="1300" />
            </div>
          <Footer /> 
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default Success;
